import './scheme.css'
import Modal from '../Modal/Modal'
import icons from '../../icons/icons';
import BuildingScheme from '../BuildingScheme/buildingScheme';
import { Link } from 'react-router-dom';
import { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Scheme(props) {
    const [buildings,setBuildings] = useState(null)
    const [users,setUsers] = useState(null)

    const navigate = useNavigate()

    const url = '/core_api/building/'

    const axiosInstance = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization':`Token ${localStorage.getItem('authToken')}`
        }
    })

    useEffect(() => {
        async function fetchData() {
            let url1 = '/core_api/access/'

            await axiosInstance.get(url,{params:{filter_scheme_id:props.id}})
                .then(data => setBuildings(data.data))
                .catch(e => console.log(e))

            await axiosInstance.get(url1,{params:{filter_scheme_id:props.id}})
                .then(data => setUsers(data.data.results))
                .catch(e => console.log(e))
        }

        fetchData()
    }, [props.id])

    const cachedData = useMemo(() => {
        if (!buildings) return null
        else return parseData(buildings)
    },[buildings])

    const removeScheme = function() {
        props.removeScheme(Number(props.id))
    }

    return(
        <div id={props.id} className='scheme'>
            <div className='scheme__author'>
                <p>Автор</p>
                {`${props.author?.username}${props.author?.is_superuser ? '[SU]' : ''}`}
                <div className='scheme__author__edit' onClick={() => {navigate(`/userList/${props.id}/`,{state:{description:`Пользователи ${props.name}`}})}}>
                    {icons.user_edit}
                    {users ? users.length : '?'}
                </div>
            </div>
            {icons.schemes.scheme}
            <div id='scheme__body' className='scheme__body'>
                <p className='scheme__body__title'>{props.name}</p>
                <div id='scheme__body__buildings' className='scheme__body__buildings'>
                    {
                        buildings && buildings.length === 0 ? 
                        <div className="scheme__body__buildings__add-new">{'[ Пустая схема ]'}</div> : 
                        ''
                    }
                    {
                        !cachedData ? 
                            'Fetching data...' :
                            cachedData
                    }
                </div>
                <div id='scheme__body__controls' className='scheme__body__controls'>
                    <Link to={`/schemes/${props.id}/${props.name}/editing`} state={{description:`${props.name}`}}>{icons.schemes.edit}Изменить</Link>
                    <Link to={`/schemes/${props.id}/${props.name}/viewing`}>{icons.schemes.view}Просмотреть</Link>
                    <button id='delete' className='scheme__body__controls__button' onClick={removeScheme}>{icons.schemes.delete}Удалить</button>
                </div>
            </div>
        </div>
    );
}

function parseData(data) {
    return data.map(elem => (<BuildingScheme id={elem.id} number={elem.number}/>))
}

export default Scheme;