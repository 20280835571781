import './MultipleSelect.css'
import { useState,useRef,useEffect } from 'react'
import icons from '../../icons/icons'
import axios from 'axios'

const MultipleSelect = function({ style,subStyle,placeholder,editable,value,getValue,reset }) {
    const [selection,setSelection] = useState(null)
    const [showDropdown,setShowDropdown] = useState(false)

    const rootRef = useRef(null)
    const inputRef = useRef(null)
    const fieldRef = useRef(null)

    useEffect(() => {
        function handleClickOutside(event) {
          if (rootRef.current && !rootRef.current.contains(event.target)) {
            setShowDropdown(false)
          }
        }
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
        
    }, []);

    useEffect(() => {
        setSelection(value)
    },[value])

    const toggleDropdown = function(e) {
        if (e.target == fieldRef.current) {
            setShowDropdown(prevValue => (!prevValue))
        }
    }

    const addOption = function() {
        if (selection) {
            var data = selection
            data = [...data,Number(inputRef.current.value)]
            data = [...new Set(data)]
            setSelection(data)
        }
        else {
            var data = selection
            data = [Number(inputRef.current.value)]
            data = [...new Set(data)]
            setSelection(data)
        }
        getValue(data)
    }

    const onDelete = function(e) {
        let id = Number(e.target.getAttribute('id'))
        let data = selection
        data = data.filter((item) => item !== id)

        if (data.length > 0) {
            setSelection(data)
            getValue(data)
        }
        else {
            setSelection(null)
            getValue(null)
        }
        
    }

    return(
        <div className='multiple-select' style={style} ref={rootRef}>
            <div 
                ref={fieldRef}
                className="multiple-select__field" 
                style={selection ? subStyle : {...subStyle,justifyContent:'center'}} 
                onClick={toggleDropdown}
            >                
                {selection ? selection.map(num => 
                    <div className='multiple-select__field__item'>{num}
                        <div id={num} className='multiple-select__field__item__delete' onClick={onDelete}>{icons.crest}</div>
                    </div>
                ) : placeholder}

                {selection ? '' : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M137.4 41.4c12.5-12.5 32.8-12.5 45.3 0l128 128c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8H32c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l128-128zm0 429.3l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128c-12.5 12.5-32.8 12.5-45.3 0z"/></svg>}
            </div>
            <div className={showDropdown === false ? "multiple-select__dropdown" : "multiple-select__dropdown multiple-select__dropdown--active"}>
                {editable ? 
                    <div className='multiple-select__dropdown__add-new__form'>
                        <input ref={inputRef} type='number' step={10}></input>
                        <div className='multiple-select__dropdown__add-new__form__button--add' onClick={addOption}>
                            {icons.squarePlus}
                        </div>
                    </div> : ''
                }
            </div>
        </div>
    )
}

export default MultipleSelect