import './Info.css'
import { Tooltip } from 'react-tooltip'
import icons from '../../../icons/icons'

const Info = function({data}) {
    const pstyle = {
        color:'white',
        textAlign:'left',
        fontSize:'1.2rem',
        textWrap:'nowrap',
        padding:'0',
    }

    const liStyle = {
        color:'white',
        textAlign:'left',
        fontSize:'1.2rem',
        textWrap:'nowrap',
        padding:'0',
        listStyle:'none'
    }

    return (
        <div className='info' data-tooltip-id={`conInfo${data.port.id}`}>
            {icons.info}
            <Tooltip id={`conInfo${data.port.id}`} style={{backgroundColor:'rgb(0,48,143)'}}>
                <ul>
                    <li style={liStyle}>{`Здание: ${data.building.number}`}</li>
                    <li style={liStyle}>{`Помещение: ${data.room.number}`}</li>
                    <li style={liStyle}>{`Серверная стойка: ${data.server_rack.title}`}</li>
                    <li style={liStyle}>{`Юнит: ${data.unit.uid} (${data.unit.side})`}</li>
                    <li style={liStyle}>{`Оборудование: ${data.equipment.manufacturer} ${data.equipment.model}`}</li>
                    <li style={liStyle}>{`Порт: ${data.port.uid} (${data.port.ip ? data.port.ip : 'null'})`}</li>
                </ul>
            </Tooltip>
        </div>
    )
}

export default Info