import { useParams,useNavigate } from 'react-router-dom'
import './Device.css'
import axios from 'axios'
import { useState,useEffect } from 'react'
import Socket from '../Socket/Socket'
import SfpTemplate from '../SfpTemplate/SfpTemplate'
import icons from '../../icons/icons'
import Modal from '../Modal/Modal'
import { toast } from 'sonner'
import Info from '../Socket/Info/Info'

const Device = function() {
    const navigate = useNavigate()
    const goBack = function() {
        navigate(-1)
    }

    const {id} = useParams()
    const url1 = `/core_api/equipment/${id}/`
    const url2 = '/core_api/port/'
    const url3 = '/core_api/sfp_template/'

    const [modalActive,setModalActive] = useState(false)
    const [action,setAction] = useState('delSfpTemplate')
    const [data,setData] = useState(null)
    const [ports,setPorts] = useState(null)
    const [showSfp,setShowSfp] = useState(false)
    const [sfpData,setSfpData] = useState(null)
    const [templateId,setTemplateId] = useState(null)
    const [portId,setPortId] = useState(null)
    const [selectedSockets,setSelectedSockets] = useState([])

    const axiosInstance = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization':`Token ${localStorage.getItem('authToken')}`
        }
    })

    useEffect(() => {
        async function fetchEquipmentData() {
            await axiosInstance.get(url1)
                .then(data => setData(data))
                .catch(e => console.log(e))

            await axiosInstance.get(url2,{params:{filter_equipment:id,order_by:'uid'}})
                .then(data => setPorts(data))
                .catch(e => console.log(e))
        }

        async function fetchSfpData() {
            await axiosInstance.get(url3)
                .then(data => setSfpData(data.data.results))
                .catch(e => console.log(e))
        }

        fetchEquipmentData()
        fetchSfpData()
    }, [])

    const toggleShowSfp = function() {
        setShowSfp(!showSfp)
    }

    const buildEquipmentStructure = function(data) {
        let areaId = 1
        let socketId = 1

        function spawnSockets(num) {
            let arr = []
            
            for (let i = 0;i < num;i++) {
                let port = ports ? ports.data.find(item => item.uid === socketId) : ''

                arr.push(
                    <Socket 
                        socketData={{
                            id:port.id,
                            uid:socketId,
                            state:port.type.modular === false ? '' : (port.sfp !== null ? icons.circleFilled : icons.circleHole)
                        }}
                        data={{...port}}
                        onselect={selectSocket}
                        onconnect={setupConnection}
                        onDelSfp={unplugSfp}
                        onDisconnect={disconnect}
                    />
                )
                socketId += 1
            }

            return arr
        }

        let subArr = []
        let subSubArr = []
        for (let temp in data) {
            subArr.push(data[temp])
        }

        subSubArr = subArr.sort((a,b) => a.unit[0] - b.unit[0])

        function spawnAreas(data) {
            let arr = []

            for (let temp in data) {
                arr.push(
                    <div 
                        className='device__wrapper__socket-area'
                        style={{
                            gridTemplateColumns:`repeat(${data[temp].count/2},1fr)`,
                            gridArea:`area${areaId}`,
                            gridTemplateRows:`repeat(${data[temp].lines},1fr)`,
                            gridRow:`${data[temp].unit[0]}`
                        }}
                    >
                        {spawnSockets(data[temp].count)}
                    </div>
                )
                areaId += 1
            }

            return arr
        }

        return spawnAreas(subSubArr)
    }

    const generateTemplateAreas = function(data) {
        let areaId = 1
        let tempAreas = []
        let subArr = []
        let subSubArr = []
        let units = []

        for (let temp in data) {
            subArr.push(data[temp])
        }

        units = [...new Set(subArr.map(item => item.unit[0]))]
        
        for (let i = 0;i < units.length;i++) {
            subSubArr.push(subArr.filter(item => item.unit[0] === units[i]))
        }

        let targetLength = 1

        for (let i in subSubArr) {
            let arr = []
            for (let j in subSubArr[i]) {
                arr.push(`area${areaId}`)
            }
            if (arr.length > targetLength) {
                targetLength = arr.length
            }
        }

        for (let i in subSubArr) {
            let arr = []
            for (let j in subSubArr[i]) {
                arr.push(`area${areaId}`)
                areaId += 1
            }
            if (arr.length < targetLength) {
                for (let i = 0; i < targetLength-1; i++) {
                    arr.push(arr[arr.length - 1]);
                }
            }
            tempAreas.push(arr)
        }

        return tempAreas.map(item => `"${item.join(' ')}"`).join(' ')
    }

    const confirmDeletion = function(id) {
        setTemplateId(id)
        setAction('delSfpTemplate')
        setModalActive(true)
    }

    const deleteSfpTemplate = async function(state) {
        if (state === true) {
            let url = `/core_api/sfp_template/${templateId}/`
            let sfpName = sfpData.find(item => item.id === templateId).name

            let updateSfpTemplates = function() {
                setSfpData(sfpData => sfpData.filter(item => item.id !== templateId))
                toast.success(
                    <div className='toast-message--success'>{icons.success} Шаблон SFP {sfpName} был успешно удален!</div>,
                    {duration:5000,position:'bottom-left'}
                )
            }

            axiosInstance.delete(url)
                .then(data => updateSfpTemplates())
                .catch(e => console.log(e))
        }
        else {
            setTemplateId(null)
            setAction('delSfpTemplate')
        }
    }

    const showModal = function() {
        setAction('addSfpTemplate')
        setModalActive(true)
    }

    const addSfpTemplate = async function(manufacturer_id,name,type_port_id,line_type,speed) {
        let url = '/core_api/sfp_template/'
        
        let data = {
            manufacturer_id:manufacturer_id,
            name:name,
            type_port_id:type_port_id,
            line_type:line_type,
            speed:speed
        }

        let updateSfpTemplates = function(data) {
            setSfpData([...sfpData,{
                id:data.id,
                manufacturer:data.manufacturer,
                name:data.name,
                type_port:data.type_port,
                speed:data.speed,
                line_type:data.line_type
            }])
            toast.success(
                <div className='toast-message--success'>{icons.success} Шаблон SFP {data.name} был успешно создан!</div>,
                {duration:5000,position:'bottom-left'}
            )
        }

        await axiosInstance.post(url,data)
            .then(data => updateSfpTemplates(data.data))
            .catch(e => console.log(e))
    }

    const selectSocket = function(uid,state) {
        let id = ports.data.find(item => item.uid === uid).id
        if (state === true) {
            setSelectedSockets([...selectedSockets,id])
        }
        else {
            setSelectedSockets(selectedSockets.filter(item => item !== id))
        }
    }

    const checkSelectedSockets = function(sockets) {
        let arr = sockets.map(item => ports.data.find(port => port.id === item).type.modular)

        return arr.includes(false) ? false : true
    }

    const plugSfp = async function(sfpId) {
        if (selectedSockets.length === 0) {
            toast.error(
                <div className='toast-message--error'>{icons.error} Выберите доступные для подключения модульные сокеты в оборудовании!</div>,
                {duration:5000,position:'bottom-left'}
            )
        }
        else {
            if (checkSelectedSockets(selectedSockets) === false) {
                toast.error(
                    <div className='toast-message--error'>{icons.error} Среди выбранных сокетов присутствуют не модульные!</div>,
                    {duration:5000,position:'bottom-left'}
                )
            }
            else {
                let url = `/core_api/sfp_template/${sfpId}/adding_to_port/`

                let data = {
                    port_list:selectedSockets,
                    equipment_id:id
                }

                let updateData = async function() {
                    await axiosInstance.get(url1)
                        .then(data => setData(data))
                        .catch(e => console.log(e))

                    await axiosInstance.get(url2,{params:{filter_equipment:id,order_by:'uid'}})
                        .then(data => setPorts(data))
                        .catch(e => console.log(e))

                    toast.success(
                        <div className='toast-message--success'>{icons.success} Выбранный SFP успешно подключен в сокеты!</div>,
                        {duration:5000,position:'bottom-left'}
                    )
                }

                await axiosInstance.put(url,data)
                    .then(data => updateData())
                    .catch(e => console.log(e))
            }
        }
    }

    const setupConnection = function(id) {
        setAction('connection')
        setPortId(id)
        setModalActive(true)
    }

    const connectSockets = async function(endpointId) {
        let url = `/core_api/port/${portId}/`

        let data = {
            connection_id:endpointId
        }

        let updateData = async function() {
            await axiosInstance.get(url1)
                .then(data => setData(data))
                .catch(e => console.log(e))

            await axiosInstance.get(url2,{params:{filter_equipment:id,order_by:'uid'}})
                .then(data => setPorts(data))
                .catch(e => console.log(e))

            toast.success(
                <div className='toast-message--success'>{icons.success} Подключение произведено успешно!</div>,
                {duration:5000,position:'bottom-left'}
            )
        }

        await axiosInstance.put(url,data)
            .then(data => updateData())
            .catch(e => console.log(e))
    }

    const unplugSfp = async function(portId) {
        let url = `/core_api/port/disconnect_sfp/`

        let data = {
            port_list:[portId],
            equipment_id:id
        }

        await axiosInstance.put(url,data)
            .then(data => setPorts(data))
            .catch(e => console.log(e))
    }

    const disconnect = async function(portId) {
        let url = `/core_api/port/disconnect_port_list/`

        let data = {
            port_list:[portId],
            equipment_id:id
        }

        await axiosInstance.put(url,data)
            .then(data => {
                setPorts(data)
                toast.success(
                    <div className='toast-message--success'>{icons.success} Подключение разорвано успешно!</div>,
                    {duration:5000,position:'bottom-left'}
                )
            })
            .catch(e => console.log(e))
    }

    return (
        <>
            <div className={showSfp ? "sfp-container" : "sfp-container sfp-container--hidden"}>
                <div className="sfp-container__thumb" onClick={toggleShowSfp}>
                    {showSfp ? icons.arrowRight : icons.arrowLeft}
                </div>
                <div className="sfp-container__title">
                    Шаблоны SFP
                    <div className="sfp-container__title__add-new" onClick={showModal}>
                        {icons.plus}
                    </div>
                </div>
                <div className="sfp-container__wrapper">
                    {sfpData ? sfpData.map(item => 
                        <SfpTemplate
                            id={item.id}
                            name={item.name}
                            manufacturer={item.manufacturer}
                            type_port={item.type_port}
                            speed={item.speed}
                            line_type={item.line_type}
                            delTemplate={confirmDeletion}
                            onplug={plugSfp}
                        />
                    ) : ''}
                </div>
            </div>
            <div className='device'>
                <p className='device__title'>{!data ? 
                    'Fetching data' : 
                    `${data.data.template.manufacturer} ${data.data.template.model}`}
                </p>
                <div 
                    className='device__wrapper' 
                    style={!ports ? 
                            {} : 
                            {
                                gridTemplateRows:`repeat(${data.data.template.number_of_units},1fr)`,
                                gridTemplateAreas:generateTemplateAreas(data.data.count_port_template_dict)
                            }
                    }
                >
                    {!ports ? 
                        'Fetching data' : 
                        buildEquipmentStructure(data.data.count_port_template_dict)
                    }
                </div>
                <div className='device__detailed'>
                    <div className='device__detailed__header'>
                        <p>№</p>
                        <p>Тип</p>
                        <p>Мод</p>
                        <p>SFP</p>
                        <p>Скорость</p>
                        <p>Vlan</p>
                        <p>IP</p>
                        <p>Mac</p>
                        <p>Подключение</p>
                    </div>
                    <div className='device__detailed__body'>
                        {!ports ? 
                            '' : 
                            ports.data.map(item => (
                                <div className="device__detailed__body__note">
                                    <p>{item.uid}</p>
                                    <p>{item.type.name ? item.type.name : '[SFP]'}</p>
                                    <p>{item.type.modular ? 'Да' : 'Нет'}</p>
                                    <p>{item.sfp ? item.sfp.name : '-/-'}</p>
                                    <p>{item.sfp ? item.sfp.speed[0] : `${item.speed}`}</p>
                                    <p>{item.vlan ? item.vlan : '-/-'}</p>
                                    <p>{item.ip ? item.ip : '-/-'}</p>
                                    <p>{item.mac ? item.mac : '-/-'}</p>
                                    <p>{Object.keys(item.connection).length > 0 ? <Info data={item.connection} /> : '-/-'}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <Modal
                active={modalActive}
                setActive={setModalActive}
                action={action}
                getResult={action === 'delSfpTemplate' ? deleteSfpTemplate : action === 'addSfpTemplate' ?  addSfpTemplate : connectSockets}
            />
        </>
    )
}

export default Device