import './buildingNode.css'
import icons from '../../icons/icons';
import { Link } from 'react-router-dom';
import { Handle, Position, useReactFlow } from 'reactflow';
 
function BuildingNode({ data }) {
    const {setNodes} = useReactFlow()

    function removeNode() {
        data.toRes({
            id: data.id,
            type:'buildingNode',
            position: { x: 100, y: 100 },
            data: {
                id: data.id,
                name:data.name,
                toRes:data.toRes
            }
        })
        setNodes((prevNodes) => prevNodes.filter((node) => node.id !== data.id))
    }

    return (
        <div id={data.id} className='building'>
            <Handle style={{width:'1.5rem', height:'1.5rem'}} type="target" position={Position.Left} id='lin' />
            <Handle style={{width:'1.5rem', height:'1.5rem'}} type="source" position={Position.Right} id="rout" />
            {icons.bn.building}
            <p className='building__title'>{data.name}</p>
            <svg
                onClick={removeNode}
                className='building__delete' xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"><path fill='red' d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm88 200H296c13.3 0 24 10.7 24 24s-10.7 24-24 24H152c-13.3 0-24-10.7-24-24s10.7-24 24-24z"/>
            </svg>
            <Link to={`/cabinets/${data.id}/editing`} state={{description:`${data.name}`}}><svg className='building__view' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/></svg></Link>
        </div>
    );
}

export default BuildingNode