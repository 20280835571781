import './Modal.css'
import { useEffect, useRef, useState } from 'react'
import CustomSelect from '../../subComponents/CustomSelect/CustomSelect'
import CustomCheckBox from '../../subComponents/CustomCheckBox/CustomCheckBox'
import SocketGroup from '../SocketGroup/SocketGroup'
import DevicePreview from '../DevicePreview/DevicePreview'
import icons from '../../icons/icons'
import axios from 'axios'
import { toast } from 'sonner'

const Modal = function({active,setActive,action,subData,getResult}) {
    const url1 = '/core_api/equipment_template/'
    const url2 = '/core_api/port_template/'
    const url3 = '/core_api/scheme/'

    const axiosInstance = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization':`Token ${localStorage.getItem('authToken')}`
        }
    })

    const [manufacturers,setManufacturers] = useState([])
    const [portTypes,setPortTypes] = useState([])
    const [sectionId,setSectionId] = useState(0)
    const [device,setDevice] = useState({})
    const [socketGroups,setSocketGroups] = useState([])
    const [schemeName,setSchemeName] = useState(null)
    const [buildingNumber,setBuildingNumber] = useState(null)
    const [cabinetNumber,setCabinetNumber] = useState(null)
    const [cabinetType,setCabinetType] = useState(null)
    const [rackTitle,setRackTitle] = useState(null)
    const [rackUnits,setRackUnits] = useState(null)
    const [rackPower,setRackPower] = useState(null)
    const [sfpManufacturer,setSfpManufacturer] = useState(null)
    const [sfpPortType,setSfpPortType] = useState(null)
    const [sfpMode,setSfpMode] = useState(null)
    const [sfpSpeed,setSfpSpeed] = useState(null)
    const [buildings,setBuildings] = useState(null)
    const [rooms,setRooms] = useState(null)
    const [racks,setRacks] = useState(null)
    const [devices,setDevices] = useState(null)
    const [equipmentId,setEquipmentId] = useState(null)
    const [equipmentType,setEquipmentType] = useState(null)
    const [socketToConnect,setSocketToConnect] = useState(null)
    const [socketsToConnect,setSocketsToConnect] = useState(null)
    const [readRole,setReadRole] = useState(true)
    const [writeRole,setWriteRole] = useState(false)
    const [username,setUsername] = useState(null)

    const refSfpName = useRef(null)
    const refUnits = useRef(null)
    const refDeviceName = useRef(null)
    const refDevicePower = useRef(null)

    useEffect(() => {
        if (document.getElementsByClassName('input--big').length > 0) {
            document.getElementsByClassName('input--big')[0].focus()
        }
        const fetchManufacturersData = async function() {
            let url = '/core_api/manufacturer/'
            await axiosInstance.get(url)
                .then(data => setManufacturers(data.data.results))
                .catch(e => console.log(e))
        }

        const fetchPortTypesData = async function() {
            let url = '/core_api/type_port/'
            await axiosInstance.get(url)
                .then(data => setPortTypes(data.data))
                .catch(e => console.log(e))
        }

        if (action === 'connection') {
            const fetchBuildings = async function() {
                let url = `/core_api/building/`

                await axiosInstance.get(url,{params:{filter_scheme_id:localStorage.getItem('currentScheme')}})
                    .then(data => setBuildings(data.data))
                    .catch(e => console.log(e))
            }
            fetchBuildings()
        }

        fetchManufacturersData()
        fetchPortTypesData()
    },[active])

    const closeModal = function() {
        setActive(false)
    }

    const handleKeyDown = function(event) {
        if  (event.key === 'Escape') {
            closeModal()
        }
        else if (event.key === 'Enter') {
            closeModal()
        }
    }

    const handleInputManufacturer = function(value) {
        let fetchManufacturersData = async function() {
            let url = '/core_api/manufacturer/'
            await axiosInstance.get(url)
                .then(data => setManufacturers(data.data.results))
                .catch(e => console.log(e))
        }
        fetchManufacturersData()

        setDevice({...device,manufacturer:value})
    }

    const handleInputType = function(value) {
        setDevice({...device,type:value})
    }

    const handleInputModel = function(event) {
        setDevice({...device,model:event.target.value})
    }

    const handleInputPower = function(event) {
        setDevice({...device,power:event.target.value})
    }

    const appendSection = function() {
        setSectionId(sectionId + 1)
        setSocketGroups(prevGroups => [...prevGroups,{id:sectionId}])
    }

    const deleteSection = function(id) {
        setSocketGroups(prevGroups => prevGroups.filter(item => item.id !== id))
    }

    const updateSocketGroups = function(id,field,value) {
        let subArray = socketGroups

        function setParam(item,field,value) {
            if (field === 'unit') {
                item.unit = value.unit
            }
            else if (field === 'name') {
                item.name = value.name
            }
            else if (field === 'count') {
                item.count = value.count
            }
            else if (field === 'speed') {
                item.speed = value.speed
            }
            else if (field === 'type_port') {
                item.type_port = value.type_port
            }
            else if (field === 'modular') {
                item.modular = value.modular
            }
            else if (field === 'lines') {
                item.lines = value.lines
            }

            return item
        }
        subArray = subArray.map(item => item.id === id ? setParam(item,field,value) : item)

        setSocketGroups(subArray)
    }

    const addDevice = async function() {
        let number_of_units = [...new Set(socketGroups.map(item => item.unit))].length

        let data1 = {
            manufacturer_id:manufacturers.find(item => item.name === device.manufacturer).id,
            type:device.type,
            model:device.model,
            number_of_units:number_of_units, 
            power:Number(device.power),
        }

        const createPorts = async function (eqtmp) {
            for (let item of socketGroups) {
                let data = item.speed ? 
                {
                    name:item.name,
                    count:Number(item.count),
                    speed:[...item.speed],
                    equipment_tmp_id:eqtmp,
                    type_port_id:item.type_port ? portTypes.find(t => t.name === item.type_port).id : null,
                    modular:item.modular ? item.modular : false,
                    unit:[Number(item.unit)],
                    lines:item.lines
                } : 
                {
                    name:item.name,
                    count:Number(item.count),
                    equipment_tmp_id:eqtmp,
                    speed:[0],
                    type_port_id:item.type_port ? portTypes.find(t => t.name === item.type_port).id : null,
                    modular:item.modular ? item.modular : false,
                    unit:[Number(item.unit)],
                    lines:item.lines
                }
    
                await axiosInstance.post(url2,data)
                    .then(data => console.log(data))
                    .catch(e => console.log(e))
            }
        }

        await axiosInstance.post(url1,data1)
            .then(data => createPorts(data.data.id))
            .catch(e => console.log(e))
        
        getResult()
        closeModal()
        setDevice({})
        setSocketGroups([])
        refDeviceName.current.value = ''
        refDevicePower.current.value = ''
    }

    const handleInputSchemeName = function(e) {
        setSchemeName(e.target.value)
    }

    const addScheme = async function() {
        let data = {
            title:schemeName
        }

        function postScheme() {
            axiosInstance.post(url3,data)
                .then(data => getResult(data.data.id, data.data.title, data.data.creator))
                .catch(e => console.log(e))
        }
        postScheme()
        closeModal()
    }

    const removeScheme = function(e) {
        if (e.target.getAttribute('id') === 'Yes') {
            getResult(true)
            closeModal()
        }
        else {
            getResult(false)
            closeModal()
        }
    }

    const handleInputBuildingNumber = function(e) {
        setBuildingNumber(e.target.value)
    }

    const addBuilding = function() {
        getResult(buildingNumber)
        closeModal()
    }

    const removeBuilding = function(e) {
        if (e.target.getAttribute('id') === 'Yes') {
            getResult(true)
            closeModal()
        }
        else {
            getResult(false)
            closeModal()
        }
    }

    const handleInputCabinetNumber = function(e) {
        setCabinetNumber(e.target.value)
    }

    const handleInputCabinetType = function(value) {
        if (value === 'Серверное') {
            setCabinetType('Серверная')
        }
        else {
            setCabinetType('Обычная')
        }
    }

    const addCabinet = function() {
        getResult(cabinetNumber,cabinetType)
        closeModal()
    }

    const removeCabinet = function(e) {
        if (e.target.getAttribute('id') === 'Yes') {
            getResult(true)
            closeModal()
        }
        else {
            getResult(false)
            closeModal()
        }
    }

    const handleInputRackTitle = function(e) {
        setRackTitle(e.target.value)
    }

    const handleInputRackUnits = function(e) {
        setRackUnits(e.target.value)
    }

    const handleInputRackPower = function(e) {
        setRackPower(e.target.value)
    }

    const addRack = function() {
        getResult(rackTitle,Number(rackUnits),Number(rackPower))
        closeModal()
    }

    const removeRack = function(e) {
        if (e.target.getAttribute('id') === 'Yes') {
            getResult(true)
            closeModal()
        }
        else {
            getResult(false)
            closeModal()
        }
    }

    const removeEquipment = function(e) {
        if (e.target.getAttribute('id') === 'Yes') {
            getResult(true)
            closeModal()
        }
        else {
            getResult(false)
            closeModal()
        }
    }

    const removeEquipmentTemplate = function(e) {
        if (e.target.getAttribute('id') === 'Yes') {
            getResult(true)
            closeModal()
        }
        else {
            getResult(false)
            closeModal()
        }
    }

    const removeSfpTemplate = function(e) {
        if (e.target.getAttribute('id') === 'Yes') {
            getResult(true)
            closeModal()
        }
        else {
            getResult(false)
            closeModal()
        }
    }

    const handleInputSfpManufacturer = function(value) {
        manufacturers ? setSfpManufacturer(manufacturers.find(item => item.name === value).id) : setSfpManufacturer(null)
    }

    const handleInputSfpPortType = function(value) {
        portTypes ? setSfpPortType(portTypes.find(item => item.name === value).id) : setSfpPortType(null)
    }

    const handleInputSfpMode = function(value) {
        setSfpMode(value)
    }

    const handleInputSfpSpeed = function(value) {
        setSfpSpeed([Number(value)])
    }

    const addSfpTemplate = function() {
        getResult(sfpManufacturer,refSfpName.current.value,sfpPortType,sfpMode,sfpSpeed)
        closeModal()
    }

    const handleUnitsScroll = function(e) {
        // if(refUnits.current) {
        //     e.preventDefault()
        //     refUnits.current.scrollBy({left:e.deltaY < 0 ? -50 : 50})
        // }
    }

    const fetchRooms = async function(value) {
        let id = buildings.find(item => item.number === value).id

        let url = `/core_api/room/`

        await axiosInstance.get(url,{params:{filter_building_id:id}})
            .then(data => setRooms(data.data.results))
            .catch(e => console.log(e))
    }

    const fetchRacks = async function(value) {
        let id = rooms.find(item => item.number === value).id

        let url = `/core_api/server_rack/`

        await axiosInstance.get(url,{params:{filter_room_id:id}})
            .then(data => setRacks(data.data))
            .catch(e => console.log(e))
    }

    const fetchDevices = async function(value) {
        let id = racks.find(item => item.title === value).id

        let url = `/core_api/equipment/`

        await axiosInstance.get(url,{params:{filter_server_rack_id:id}})
            .then(data => setDevices(data.data.results))
            .catch(e => console.log(e))
    }

    const fetchEquipment = function(value) {
        setEquipmentId(devices.find(item => value.includes(item.template.model)).id)
        setEquipmentType(devices.find(item => value.includes(item.template.model)).template.type)
    }

    const selectSocketToConnect = function(id) {
        setSocketToConnect(id)
    }

    const selectSocketsToConnect = function(sockets) {
        setSocketsToConnect(sockets)
    }

    const setupConnection = function() {
        if (equipmentType && equipmentType !== 'Пассивное оборудование') {
            if (socketToConnect) {
                getResult(socketToConnect)
                closeModal()
            }
            else {
                toast.error(
                    <div className='toast-message--error'>{icons.error} Пожалуйста, выберите порт для подключения!</div>,
                    {duration:5000,position:'bottom-left'}
                )
            }
        }
        else {
            if (socketsToConnect) {
                getResult({sockets:socketsToConnect,eqId:equipmentId})
                closeModal()
            }
            else {
                toast.error(
                    <div className='toast-message--error'>{icons.error} Пожалуйста, выберите порт для подключения!</div>,
                    {duration:5000,position:'bottom-left'}
                )
            }
        }
    }

    const addUser = function() {
        getResult(username,writeRole ? 'Change' : 'Read')
        closeModal()
    }

    const delUser = function(e) {
        getResult(e.target.getAttribute('id') === 'Yes' ? true : false)
        closeModal()
    }

    return (
        <div className={active === true ? 'modal modal-active' : 'modal'}>
            <div className="modal__content" autoFocus onKeyDown={handleKeyDown} tabIndex={-1}>
                {action && action.includes('del') ? '' : <svg className='modal__content__close' onClick={closeModal} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>}
                {action === 'addEquipmentTemplate' ? 
                    <div className='modal__content__wrapper'>
                        <CustomSelect 
                            placeholder={'<Производитель>'} 
                            target='manufacturer'
                            editable={true}
                            options={manufacturers.map(item => item.name)} 
                            getValue={handleInputManufacturer}
                            value={device.manufacturer ? device.manufacturer : null}
                        />
                        <CustomSelect 
                            placeholder={'<Тип устройства>'} 
                            editable={false}
                            options={['Сервер','Коммутатор','Пассивное оборудование']} 
                            getValue={handleInputType}
                            value={device.type ? device.type : null}
                        />
                        <input ref={refDeviceName} className='input--big' type="text" placeholder='<Название/Модель>' autoFocus onChange={handleInputModel}/>
                        <input ref={refDevicePower} className='input--big' type="number" min={10} max={220} step={5} onChange={handleInputPower} placeholder='<Мощность(Вт)>' />
                        <div className="modal__content__wrapper__units">
                            <div className="modal__content__wrapper__units__title">
                                Сокеты / Порты
                                <div className='modal__content__wrapper__units__title__add-new' onClick={appendSection}>{icons.plus}</div>
                            </div>
                            <div className="modal__content__wrapper__units__body" ref={refUnits} onWheel={handleUnitsScroll}>
                                {socketGroups.map(item => (
                                    <SocketGroup 
                                        id={item.id} 
                                        data={{...item}}
                                        onDelete={deleteSection}
                                        passGroupData={updateSocketGroups}
                                        axiosInstance={axiosInstance}
                                    />
                                ))}
                            </div>
                        </div>
                        <button onClick={addDevice}>Создать</button>
                    </div> : 
                action === 'addScheme' ? 
                    <div className='modal__content__wrapper'>
                        <input className='input--big' type="text" placeholder='<Название>' onChange={handleInputSchemeName} autoFocus />
                        <button onClick={addScheme}>Создать</button>
                    </div> : 
                action === 'deleteScheme' ? 
                    <div className='modal__content__wrapper'>
                        <p>Вы действительно хотите удалить выбранную схему?</p>
                        <div className='modal__content__wrapper__button-group'>
                            <button id='Yes' onClick={removeScheme}>Да</button>
                            <button id='No' onClick={removeScheme}>Нет</button>
                        </div>
                    </div> : 
                action === 'addBuilding' ? 
                    <div className='modal__content__wrapper'>
                        <input className='input--big' type="text" placeholder='<Название / Номер>' onChange={handleInputBuildingNumber} autoFocus />
                        <button onClick={addBuilding}>Создать</button>
                    </div> : 
                action === 'delBuilding' ? 
                    <div className='modal__content__wrapper'>
                        <p>Вы действительно хотите удалить выбранное здание из схемы?</p>
                        <div className='modal__content__wrapper__button-group'>
                            <button id='Yes' onClick={removeBuilding}>Да</button>
                            <button id='No' onClick={removeBuilding}>Нет</button>
                        </div>
                    </div> : 
                action === 'addCabinet' ? 
                    <div className='modal__content__wrapper'>
                        <input className='input--big' type="text" placeholder='<Название / Номер>' onChange={handleInputCabinetNumber} autoFocus />
                        <CustomSelect
                            placeholder={'<Тип помещения>'} 
                            editable={false}
                            options={['Серверное','Обычное']} 
                            getValue={handleInputCabinetType}
                        />
                        <button onClick={addCabinet}>Создать</button>
                    </div> : 
                action === 'delCabinet' ? 
                    <div className='modal__content__wrapper'>
                        <p>Вы действительно хотите удалить выбранное помещение из здания?</p>
                        <div className='modal__content__wrapper__button-group'>
                            <button id='Yes' onClick={removeCabinet}>Да</button>
                            <button id='No' onClick={removeCabinet}>Нет</button>
                        </div>
                    </div> : 
                action === 'addRack' ? 
                    <div className='modal__content__wrapper'>
                        <input className='input--big' type="text" placeholder='<Название / Номер>' onChange={handleInputRackTitle} autoFocus />
                        <input className='input--big' type="number" min={2} max={50} step={2} onChange={handleInputRackUnits} placeholder='<Кол-во юнит>' />
                        <input className='input--big' type="number" min={10} max={1000} step={10} onChange={handleInputRackPower} placeholder='<Макс. мощность(Вт)>' />
                        <button onClick={addRack}>Создать</button>
                    </div> : 
                action === 'delRack' ? 
                    <div className='modal__content__wrapper'>
                        <p>Вы действительно хотите удалить выбранную серверную стойку из помещения?</p>
                        <div className='modal__content__wrapper__button-group'>
                            <button id='Yes' onClick={removeRack}>Да</button>
                            <button id='No' onClick={removeRack}>Нет</button>
                        </div>
                    </div> : 
                action === 'delEquipment' ? 
                    <div className='modal__content__wrapper'>
                        <p>Вы действительно хотите удалить выбранное оборудование из стойки?</p>
                        <div className='modal__content__wrapper__button-group'>
                            <button id='Yes' onClick={removeEquipment}>Да</button>
                            <button id='No' onClick={removeEquipment}>Нет</button>
                        </div>
                    </div> : 
                action === 'delEquipmentTemplate' ? 
                    <div className='modal__content__wrapper'>
                        <p>Вы действительно хотите удалить выбранный шаблон оборудования?</p>
                        <div className='modal__content__wrapper__button-group'>
                            <button id='Yes' onClick={removeEquipmentTemplate}>Да</button>
                            <button id='No' onClick={removeEquipmentTemplate}>Нет</button>
                        </div>
                    </div> : 
                action === 'delSfpTemplate' ? 
                    <div className='modal__content__wrapper'>
                        <p>Вы действительно хотите удалить выбранный шаблон SFP?</p>
                        <div className='modal__content__wrapper__button-group'>
                            <button id='Yes' onClick={removeSfpTemplate}>Да</button>
                            <button id='No' onClick={removeSfpTemplate}>Нет</button>
                        </div>
                    </div> : 
                action === 'addSfpTemplate' ? 
                    <div className='modal__content__wrapper'>
                        <input className='input--big' type="text" placeholder='<Модель>' ref={refSfpName} autoFocus />
                        <CustomSelect
                            placeholder={'<Производитель>'} 
                            target='manufacturer'
                            editable={true}
                            options={manufacturers.map(item => item.name)} 
                            getValue={handleInputSfpManufacturer}
                        />
                        <CustomSelect
                            placeholder={'<Тип порта>'} 
                            editable={false}
                            options={portTypes.map(item => item.name)} 
                            getValue={handleInputSfpPortType}
                        />
                        <CustomSelect
                            placeholder={'<Модовость>'}
                            editable={false}
                            options={['Одномодовый','Многомодовый','Медный провод','None']}
                            getValue={handleInputSfpMode}
                        />
                        <CustomSelect
                            placeholder={'<Скорость(Mb/s)>'}
                            editable={false}
                            options={['10','100','1000']}
                            getValue={handleInputSfpSpeed}
                        />
                        <button onClick={addSfpTemplate}>Создать</button>
                    </div> : 
                action === 'connection' ? 
                    <div className='modal__content__wrapper'>
                        <CustomSelect
                            placeholder={'<Здание>'}
                            editable={false}
                            options={buildings ? buildings.map(item => item.number) : []}
                            getValue={fetchRooms}
                        />
                        <CustomSelect
                            placeholder={'<Помещение>'}
                            editable={false}
                            options={rooms ? rooms.map(item => item.number) : []}
                            getValue={fetchRacks}
                            resetValue={rooms}
                        />
                        <CustomSelect
                            placeholder={'<Стойка / Розетка>'}
                            editable={false}
                            options={racks ? racks.map(item => item.title) : []}
                            getValue={fetchDevices}
                        />
                        <CustomSelect
                            placeholder={'<Устройство>'}
                            editable={false}
                            options={devices ? devices.map(item => `${item.template.manufacturer} ${item.template.model}`) : []}
                            getValue={fetchEquipment}
                        />
                        {equipmentId ? <DevicePreview id={equipmentId} onselect={selectSocketToConnect} onmultipleselect={selectSocketsToConnect} /> : ''}
                        <button onClick={setupConnection}>Подключить</button>
                    </div> : 
                action === 'addUser' ? 
                <div className='modal__content__wrapper'>
                    <CustomSelect
                        placeholder={'<Пользователь>'}
                        editable={false}
                        options={subData ? subData.map(item => item.username) : []}
                        getValue={(value)=>{setUsername(value)}}
                    />
                    <div className='modal__content__wrapper__button-group'>
                        <CustomCheckBox
                            style={{fontSize:'1.5rem'}}
                            title={'Чтение'}
                            blocked={false}
                            value={readRole}
                            getValue={() => setReadRole(prevValue => !prevValue)}
                        />
                        <CustomCheckBox
                            style={{fontSize:'1.5rem'}}
                            title={'Изменение'}
                            blocked={false}
                            value={writeRole}
                            getValue={() => setWriteRole(prevValue => !prevValue)}
                        />
                    </div>
                    <button onClick={addUser}>Добавить</button>
                </div> : 
                action === 'delUser' ? 
                <div className='modal__content__wrapper'>
                    <p>Вы действительно хотите удалить выбранного пользователя из схемы?</p>
                    <div className='modal__content__wrapper__button-group'>
                        <button id='Yes' onClick={delUser}>Да</button>
                        <button id='No' onClick={delUser}>Нет</button>
                    </div>
                </div> : 
                    ''
                }
            </div>
        </div>
    )
}

export default Modal