import { useState, useEffect } from "react";
import icons from "../../icons/icons";
import "./rack.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Tooltip } from "react-tooltip";

const Rack = function ({
  id,
  name,
  length,
  max_power,
  free_power,
  free_units,
  delRack,
}) {
  const navigate = useNavigate();

  const url = `/core_api/server_rack/${id}/`;

  const axiosInstance = axios.create({
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("authToken")}`,
    },
  });

  const [equipmentCount, setEquipmentCount] = useState(null);

  useEffect(() => {
    const fetchRackData = async function () {
      await axiosInstance
        .get(url)
        .then((data) =>
          setEquipmentCount(
            new Set(
              data.data.units
                .filter((item) => item.equipment)
                .map((item) => item.equipment.id),
            ).size,
          ),
        )
        .catch((e) => console.log(e));
    };
    fetchRackData();
  }, [id]);

  const handleClick = function (e) {
    if (e.target.classList.contains("rack")) {
      navigate(`/rack/${id}`, { state: { description: `${name}` } });
    } else if (e.target.classList.contains("rack__delete")) {
      delRack(Number(id));
    }
  };

  const liStyle = {
    color: "white",
    fontSize: "1.2rem",
    textAlign: "left",
    textWrap: "nowrap",
    padding: "0",
    listStyle: "none",
  };

  return (
    <div className="rack" onClick={handleClick}>
      <div className="rack__delete">{icons.squareMinus}</div>
      <div
        className="rack__info"
        data-tooltip-id={id}
        data-tooltip-place="right"
      >
        {icons.info}
        <Tooltip
          id={`${id}`}
          style={{ backgroundColor: "rgb(0,48,143)", zIndex: "1000" }}
        >
          <ul>
            <li style={liStyle}>{`Юнитов: ${length} ед.`}</li>
            <li style={liStyle}>{`Макс. мощность: ${max_power} Вт`}</li>
            <li
              style={liStyle}
            >{`Доступная мощность: ${free_power ? free_power : max_power} Вт`}</li>
            <li
              style={liStyle}
            >{`Доступно юнитов : ${free_units ? free_units : length} ед.`}</li>
          </ul>
        </Tooltip>
      </div>
      {icons.server_rack}
      <p className="rack__title">{name}</p>
      <div className="rack__equipment-count">
        {equipmentCount ? equipmentCount : "0"}
      </div>
    </div>
  );
};

export default Rack;
