import { useEffect, useState } from 'react'
import './Navigation.css'
import NavigationLink from './NavigationLink/NavigationLink'
import { useLocation } from 'react-router-dom'

const Navigation = function({children}) {
    const location = useLocation()

    const [pathLog,setPathLog] = useState([])

    useEffect(() => {
        if (location && location?.pathname && location.pathname !== '/' && location.pathname !== '/loginPage') {
            let loc = {path:location.pathname,title:location?.state?.description}
            if (pathLog) {
                if (pathLog.find(item => item.path === loc.path)) {
                    let endPoint = pathLog.indexOf(pathLog.find(item => item.path === loc.path)) + 1
                    setPathLog(pathLog.slice(0,endPoint))
                }
                else {
                    setPathLog(oldArray => [...oldArray,loc])
                }
            }
            
        }
    },[location])

    return (
        <div className='navigation'>
            <NavigationLink index={0} to={'/'} title={'Главная'} toHomepage={() => {setPathLog([])}} isCurrent={location.pathname === '/'}/>
            {pathLog ? pathLog.map(item => <NavigationLink index={pathLog.indexOf(item)+1} to={item.path} title={item.title} isCurrent={item.path === location.pathname}/>) : ''}
        </div>
    )
}

export default Navigation