import './cabinet.css'
import icons from '../../icons/icons';
import { Link,useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useState,useEffect } from 'react';

const Cabinet = function({id,type,name,delCabinet}) {
    const navigate = useNavigate()

    const url = `/core_api/server_rack/`

    const axiosInstance = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization':`Token ${localStorage.getItem('authToken')}`
        }
    })

    const [rackCount,setRackCount] = useState(null)

    useEffect(() => {
        const fetchRacksData = async function() {
            await axiosInstance.get(url,{params:{filter_room_id:id}})
                .then(data => setRackCount(data.data.length))
                .catch(e => console.log(e))
        }
        fetchRacksData()
    }, [id])

    const handleClick = function(e) {
        if (e.target.classList.contains('cabinet')) {
            navigate(`/racks/${id}/editing`,{state:{description:`Помещение ${name}`}})
        }
        else if (e.target.classList.contains('cabinet__delete')) {
            delCabinet(id)
        }
    }

    return (
        <div className='cabinet' onClick={handleClick}>
            <div className="cabinet__delete">{icons.squareMinus}</div>
            {type === 'Обычная' ? <svg className='cabinet__icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M320 32c0-9.9-4.5-19.2-12.3-25.2S289.8-1.4 280.2 1l-179.9 45C79 51.3 64 70.5 64 92.5V448H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H96 288h32V480 32zM256 256c0 17.7-10.7 32-24 32s-24-14.3-24-32s10.7-32 24-32s24 14.3 24 32zm96-128h96V480c0 17.7 14.3 32 32 32h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H512V128c0-35.3-28.7-64-64-64H352v64z"/></svg> : <svg className='cabinet__icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M64 32C28.7 32 0 60.7 0 96v64c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm280 72a24 24 0 1 1 0 48 24 24 0 1 1 0-48zm48 24a24 24 0 1 1 48 0 24 24 0 1 1 -48 0zM64 288c-35.3 0-64 28.7-64 64v64c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V352c0-35.3-28.7-64-64-64H64zm280 72a24 24 0 1 1 0 48 24 24 0 1 1 0-48zm56 24a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z"/></svg>}
            <p className='cabinet__title'>{name}</p>
            <div className="cabinet__rack-count">{rackCount ? rackCount : '0'}</div>
        </div>
    );
}

export default Cabinet;