import './schemeDetailed.css'
import 'reactflow/dist/style.css';
import BuildingPreview from '../BuildingPreview/buildingPreview';
import ReactFlow, { useNodesState, useEdgesState, addEdge, Background, Controls } from 'reactflow';
import BuildingNode from '../buildingNode/buildingNode';
import BuildingNodeView from '../buildingNode/buildingNodeView';
import CustomEdge from '../customEdge/customEdge.tsx';
import { useState, useCallback, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import axios from 'axios';
import Modal from '../Modal/Modal.jsx';
import { toast } from 'sonner';
import icons from '../../icons/icons.jsx';

const nodeTypes = { 'buildingNode': BuildingNode };
const edgeTypes = { 'custom-edge': CustomEdge };

function SchemeDetailed(props) {
    const {id,name,status} = useParams()
    localStorage.setItem('currentScheme',`${id}`)
    const navigate = useNavigate()

    const goBack = () => navigate(-1)

    const url = '/core_api/building/'

    const [action,setAction] = useState('addBuilding')
    const [modalActive,setModalActive] = useState(false)
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [sidebarHidden,setSidebarHidden] = useState(status === 'editing' ? false : true)
    const [previewBuildings,setPreviewBuildings] = useState([])
    const [buildingId,setBuildingId] = useState(null)

    const axiosInstance = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization':`Token ${localStorage.getItem('authToken')}`
        }
    })

    const parseData = function(data) {
        let pB = []
        let wB = []

        data.map(elem => (
            !elem.coord_x && !elem.coord_y ? 
            pB.push({
                id:`${elem.id}`,
                type:'buildingNode',
                position:{x:null,y:null},
                data:{
                    id:elem.id,
                    name:elem.number,
                    toRes:toResources
                }
            }) : 
            wB.push({
                id:`${elem.id}`,
                type:'buildingNode',
                position:{x:null,y:null},
                data:{
                    id:elem.id,
                    name:elem.number,
                    toRes:toResources
                }
            })
        ))

        setPreviewBuildings(pB)
        setNodes(wB)
    }

    useEffect(() => {
        axiosInstance.get(url,{params:{filter_scheme_id:id}})
            .then(data => parseData(data.data))
            .catch(e => console.log(e))
    }, [props.id])

    const toggleSidebar = function() {
        setSidebarHidden(prevValue => (!prevValue))
        console.log(sidebarHidden)
    }

    const onConnect = useCallback(
        (connection) => {
          const edge = { ...connection, type: 'custom-edge', animated:true };
          setEdges((eds) => addEdge(edge, eds));
        },
        [setEdges],
    );
    
    const proOptions = { hideAttribution: true };

    function toWorkbench(id, name) {
        setPreviewBuildings(oldArray => (oldArray.filter(elem => elem.id !== id)))
        
        setNodes(oldArray => [...oldArray, { id: id, type:'buildingNode' , position: { x: 100, y: 100 }, data: { id: id, name:name, toRes:toResources } }])
    }

    function toResources(elem) {
        setPreviewBuildings(oldArray => [...oldArray, elem])
    }

    const showModal = function() {
        setModalActive(true)
    }

    const confirmDeletion = function(id) {
        setBuildingId(Number(id))
        setAction('delBuilding')
        setModalActive(true)
    }

    const deleteBuilding = function(state) {
        if (state === true) {
            let url = `/core_api/building/${buildingId}/`
            let buildingName = previewBuildings.find(item => item.data.id === buildingId).data.name

            let updatePreviewBuildings = function() {
                setPreviewBuildings(previewBuildings => previewBuildings.filter(item => item.id !== `${buildingId}`))
                setAction('addBuilding')
                setBuildingId(null)
                toast.success(
                    <div className='toast-message--success'>{icons.success} Здание {buildingName} было успешно удалено!</div>,
                    {duration:5000,position:'bottom-left'}
                )
            }

            axiosInstance.delete(url)
                .then(data => updatePreviewBuildings())
                .catch(e => console.log(e))
        }
        else {
            setAction('addBuilding')
            setBuildingId(null)
        }
    }

    const addBuilding = async function(number) {
        let url = '/core_api/building/'
        let data = {
            number:number,
            scheme_id:Number(id)
        }

        function updatePreviewBuildings(data) {
            setPreviewBuildings([...previewBuildings,
                {
                    id:`${data.data.id}`,
                    type:'buildingNode',
                    position:{x:null,y:null},
                    data:{
                        id:data.data.id,
                        name:data.data.number,
                        toRes:toResources
                    }
                }
            ])
            toast.success(
                <div className='toast-message--success'>{icons.success} Здание {data.data.number} было успешно создано!</div>,
                {duration:5000,position:'bottom-left'}
            )
        }

        await axiosInstance.post(url,data)
            .then(data => updatePreviewBuildings(data))
            .catch(e => console.log(e))
    }

    return(
        <div className={!props.navbarHidden ? 'scheme-detailed' : 'scheme-detailed scheme-detailed--blured'}>
            <p className='scheme-detailed__title'>{status === 'editing' ? icons.editing : icons.viewing}  {status === 'editing' ? 'Изменение схемы' : 'Просмотр схемы'} {name} 
                <p className='scheme-detailed__title__controls'></p>
            </p>
            <div className={status === 'editing' ? 'scheme-detailed__toolbar' : 'scheme-detailed__toolbar scheme-detailed__toolbar--hidden'}>
                {/* <Tooltip id='toggle-sidebar' style={{backgroundColor:'white',color:'rgb(0,48,143)',fontSize:'1.75rem'}}/> */}
                <Tooltip id='save' style={{backgroundColor:'white',color:'rgb(0,48,143)',fontSize:'1.75rem'}}/>
                <Tooltip id='clear-all' style={{backgroundColor:'white',color:'rgb(0,48,143)',fontSize:'1.75rem'}}/>
                {/* <button 
                    className={sidebarHidden === false ? 'scheme-detailed__toolbar__button scheme-detailed__toolbar__button__active' : 'scheme-detailed__toolbar__button'} 
                    onClick={toggleSidebar} data-tooltip-id='toggle-sidebar' data-tooltip-content='Toggle Sidebar' data-tooltip-place='top'>
                        <svg className='scheme-detailed__toolbar__button__icon' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16"><path d="M0 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm5-1v12h9a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1zM4 2H2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h2z"/></svg>
                </button> */}
                <button className='scheme-detailed__toolbar__button' data-tooltip-id='save' data-tooltip-content='Save' data-tooltip-place='top'>
                    <svg className='scheme-detailed__toolbar__button__icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V173.3c0-17-6.7-33.3-18.7-45.3L352 50.7C340 38.7 323.7 32 306.7 32H64zm0 96c0-17.7 14.3-32 32-32H288c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V128zM224 288a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg>
                </button>
                <button className='scheme-detailed__toolbar__button' data-tooltip-id='clear-all' data-tooltip-content='Clear All' data-tooltip-place='top'>
                    <svg className='scheme-detailed__toolbar__button__icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"/></svg>
                </button>
            </div>
            <div id='scheme-detailed__body' className='scheme-detailed__body'>
                <div className={sidebarHidden === false ? 'scheme-detailed__body__resources' : 'scheme-detailed__body__resources scheme-detailed__body__resources--hidden'}>
                    <div className='scheme-detailed__body__resources__add-new' onClick={showModal}>
                        <svg className='scheme-detailed__body__resources__add-new__icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM200 344V280H136c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H248v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"/></svg>
                        <p className='scheme-detailed__body__resources__add-new__title'>Добавить здание</p>
                    </div>
                    {!previewBuildings ? '' : previewBuildings.map(elem => (<BuildingPreview id={elem.id} name={elem.data.name} passToWorkbench={toWorkbench} delBuilding={confirmDeletion}/>))}
                </div>
                <div id='scheme-detailed__body__workbench' className={!sidebarHidden ? 'scheme-detailed__body__workbench' : 'scheme-detailed__body__workbench scheme-detailed__body__workbench__maximized'}>
                    <ReactFlow
                        style={{borderRadius:"32"}}
                        nodes={nodes}
                        edges={edges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}
                        proOptions={proOptions}
                        nodeTypes={status === 'editing' ? nodeTypes : { 'buildingNode': BuildingNodeView }}
                        edgeTypes={status === 'editing' ? edgeTypes : ''}
                        fitView
                        nodesDraggable={status === 'editing' ? true : false}
                        nodesConnectable={status === 'editing' ? true : false}
                        nodesFocusable={status === 'editing' ? true : false}
                    >
                        <Background
                            color='lightgray'
                            gap={30}
                            style={{
                                borderRadius:'2rem',
                            }}
                            variant='lines'
                        />
                        <Controls showInteractive={status === 'editing' ? true : false}/>
                    </ReactFlow>
                </div>
            </div>
            <Modal
                active={modalActive}
                setActive={setModalActive}
                action={action}
                getResult={action === 'addBuilding' ? addBuilding : deleteBuilding}
            />
        </div>
    );
}

export default SchemeDetailed;