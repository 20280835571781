import './buildingPreview.css'
import icons from '../../icons/icons';
import axios from 'axios';
import { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function BuildingPreview({id,name,passToWorkbench,delBuilding}) {
    const navigate = useNavigate(null)

    const url = `/core_api/room/`

    const axiosInstance = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization':`Token ${localStorage.getItem('authToken')}`
        }
    })

    const [roomCount,setRoomCount] = useState(null)

    useEffect(() => {
        const fetchRoomData = async function() {
            await axiosInstance.get(url,{params:{filter_building_id:id}})
                .then(data => setRoomCount(data.data.results.length))
                .catch(e => console.log(e))
        }
        fetchRoomData()
    }, [id])

    const handleClick = function(e) {
        if (e.target.classList.contains('building-preview')) {
            passToWorkbench(id, name)
        }
        else if (e.target.classList.contains('building-preview__delete')) {
            delBuilding(id)
        }
    }

    return(
        <div id={id} className='building-preview' onClick={handleClick}>
            <div className="building-preview__delete">{icons.squareMinus}</div>
            <div className="building-preview__view" onClick={() => {navigate(`/cabinets/${id}/editing`,{state:{description:`Здание ${name}`}})}}>{icons.glass}</div>
            {icons.bp.building}
            <p className='building-preview__title'>{name}</p>
            <div className="building-preview__room-count">{roomCount ? roomCount : '0'}</div>
        </div>
    );
}

export default BuildingPreview;